import Delivery from "../../assets/images/diagram/cargo-bike.png"
import Agri from "../../assets/images/diagram/agri-eco.png"
import Food from "../../assets/images/diagram/food-eco.png"
import { Button } from "../ui/button"

export default function ColsExplain(props:any) {

  const test = [
    {
      img: Agri,
      title: "Agriculture",
      subtitle: "Soutenez les producteurs locaux et l’agriculture française respectueuse de l’environnement."
    },
    {
      img: Food,
      title: "Alimentation",
      subtitle: "Sublimez votre cuisine avec des produits frais en direct des producteurs et montrez votre démarche durable à vos clients."
    },
    {
      img: Delivery,
      title: "Livraison",
      subtitle: "Des collectes de proximité et des livraisons éco-responsables en vélo cargo et bientôt 0 déchet !"
    },
  ]

  return (
    <section className="mt-36 mb-16">
      <h3 className="text-4xl font-bold text-center mb-16">Contribuez à la transition écologique<br/>en vous fournissant chez Mullo</h3>
      <div className="grid md:grid-cols-3">
        {test.map((n, i) => (
          <div key={i} className="flex flex-col px-4 lg:px-8">
            <div className="h-[180px] flex items-end">
              <img
                src={n.img}
                className="mb-4 m-auto"
                alt={n.title}
              />
            </div>
            <h4 className="text-3xl font-bold text-center">{n.title}</h4>
            <p className="mt-2 text-center">{n.subtitle}</p>
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-16">
        <Button variant="secondary" href="https://appro.mullo.fr">Commandez dès maintenant</Button>
      </div>
    </section>
  )
}