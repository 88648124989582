import Full from "../../assets/images/diagram/mullo-full.png"
import topMobile from "../../assets/images/diagram/groupe-prod.png"
import { Button } from "../ui/button"
import NavBar from "../navigation/navBar"

export default function HomeSection(props:any) {

  return (
    <section className={`relative min-h-screen flex justify-between items-center flex-col bg-${props.color}`}>
      <div className="w-full mt-10">
        <NavBar buyer={props.buyer} />
      </div>

      <div className="container px-8 md:px-24 2xl:px-0 mx-auto flex flex-col-reverse md:flex-row space-between items-center">
        <div className="max-w-xl md:pr-15 flex-1">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-secondary-dark">
            {props.buyer ? 
            "Des produits frais et locaux en direct des producteurs" 
              : 
            "Les marchés ultra locaux pour les producteurs"}
          </h1>
          <p className="text-lg md:text-2xl mt-3 md:mt-6 mb-4 md:mb-8">
            {props.buyer ? 
              "L’accès au circuit-court n’aura jamais été aussi simple" 
            : 
              "Vendre en circuit-court à vos clients professionnels n’aura jamais été aussi simple"}
          </p>
          <Button 
            variant="secondary"
            // className={`${props.buyer ? 'bg-tertiary-dark' : 'bg-secondary-dark'}`}
            href="https://forms.fillout.com/t/v9auhK6gFYus?type=websiteTest"
          >
            {props.buyer ? "S’approvisionner en ultra local" : "Rejoindre une place de marché"}
          </Button>
        </div>

        <div className="mb-10 md:hidden">
          <img
            src={topMobile}
            className="mt-10"
            alt="Regroupement producteurs"
          />
        </div>

        <div className="hidden md:block flex-2">
          <img
            src={Full}
            className="ml-5 lg:ml-16 xl:ml-24"
            alt="Regroupement producteurs"
          />
        </div>
      </div>

      <div></div>
    </section>
  )
}